import React from 'react';

import Layout from '../components/Layout';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Header from '../components/HeaderPix';
import Contact from '../components/Contact-pix';


import avatar from '../assets/images/rego.png';

const IndexPage = () => (
<Layout>
  <Header / >


  <header className="mast-dev masthead">
  <div className="d-flex h-100 align-items-center ">
    <div className="container d-flex h-100 align-items-center ">
      <div className="row mx-auto text-center">
        <h1 className="mx-auto my-0">{config.hpref}.dev</h1>
      </div>
    </div>
  </div>
  </header>


  <section id="p1" className="dev-one text-center pix-title">
    <div className="container">
      <div className="row">
        <div className="col-lg-11 mx-auto ">
          <h2 className="mb-4 ">Vehicle registration</h2>
        </div>
      </div>
      <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
        <div className="col-lg-9">
          <p className="text marg-rig">
            A .net program with Simple algorithms and file i/o. <br /><br />
            Stores number plates into a list. They then can then be edited,
            tagged or deleted. While making it, I learnt how to use bitwise
            operators with the byte datatype, in order to control what buttons
            are enabled/disabled and when. <br /><br />
            Take a look at the code
            <a className="pix-a" href="https://github.com/just-mortified/VehicleRegistrationManagement"> here.</a>
          </p>
        </div>
        <div className="col-lg-2 ">
          <img className="img-fluid pad-left" src={avatar} alt="" />
        </div>
      </div>
    </div>
  </section>

  <Contact />
  <SocialLinks />
  <Footer />
</Layout>
);

export default IndexPage;
